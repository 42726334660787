import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Typography, Button } from '@mui/material';
import styled from 'styled-components';

// Static
import {
  RouterNames,
  demoAssessmentId,
  zecodeLogo,
  StorageKeys,
  leavingTabsCount,
  candidateStatus,
} from '../../static/constants';
import { commonStrings } from '../../static/commonStrings';
import { ThemeConfigurationType } from '../Types/Index';
import { getCandidateDetails, updateAssessmentStatus } from '../../api/requests/candidate';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { removeItem, setItem } from '../../util/storage';
import ZecodeUrl from '../common/ZecodeUrl';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getNameFromEmail } from '../common/getNameFromEmail';
import { useStylesCommon } from '../common/styles';
import { setTime } from '../../api/requests/assessmentTimer';
import { convertTime } from '../../util/convertTime';
import { getPlagiarismDetails } from '../../api/requests/plagarism';
import { ZecodeText } from '../common/zecodeText';
import { ThemeContext } from '../context/themeContext';

// To do for later
//const HelpIcon = '/images/HelpIcon.svg';

//styled components
const StyledContainer = styled.div`
  min-height: 100vh;
  background-position: center;
  background-color: black;
  background-size: 100% auto;
  mix-blend-mode: overlay;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 35px 60px 0px 60px;
`;

const StyledHeader = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
`;

const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const InvitationText = styled(Typography)`
  && {
    color: var(--first-color);
    font-family: var(--bodyText-font-family);
    font-size: 28px;
    font-weight: 600;
    text-align: left;
  }
`;

const InvitationTextDescription = styled(Typography)`
  && {
    width: 40vw;
    font-family: var(--bodyText-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #707070;
  }
`;
const StyledDivider = styled(Divider)`
  && {
    background: linear-gradient(90.01deg, #d1d1d1 -7.6%, rgba(255, 255, 255, 0) 102.36%);
    width: 87%;
    height: 5px;
    border-radius: 50px;
    margin-top: 26px;
    margin-bottom: 32px;
  }
`;

const StyledCard = styled.div`
  && {
    width: 30.55vw;
    border-radius: 16px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    padding: 42px 37px;
    box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.08);
    justify-content: start;
    flex-wrap: wrap;
    gap: 42px;
    min-height: 28rem;
  }
`;

const CardHeading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    color: #000821;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
`;

const CardDescription = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--first-color);
  }
`;

const CardShortNote = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #443f3f;
  }
`;

const CardDivider = styled(Divider)`
  && {
    width: 50%;
    border-color: #bdbcbc;
  }
`;

const Footer = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--bodyText-font-family);
  text-decoration: underline;
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
  margin-left: -10px;
`;

// To do for later
// const HelpIconWrapper = styled.div`
//   color: #cccccc;
//   font-size: 14px;
//   font-family: var(--bodyText-font-family);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 5px;
// `;

const LogoImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 60px;
`;

const CandidateInvitation = () => {
  const { setThemeColor } = useContext(ThemeContext);
  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState();
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();
  const [enterpriseName, setEnterpriseName] = useState('');
  const { id, enterpriseId } = useParams();
  const navigate = useNavigate();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [candidateName, setCandidateName] = useState<string>('');
  const [candidateDetails, setCandidateDetails] = useState<any>();

  const fetchUserDetails = async () => {
    const response = await getCandidateDetails(id!);
    const candidateDetailsResponse = response?.data?.data[0];
    setCandidateDetails(candidateDetailsResponse);
    const enterprise = await getEnterpriseById(enterpriseId!);
    setEnterpriseName(enterprise?.data?.data?.name);
    setEnterpriselogo(enterprise?.data?.data?.imageURL);
    setCandidateName(getNameFromEmail(response?.data?.data[0]?.email));
    setUserName(response?.data?.data[0].name);
    setUserRole(response?.data?.data[0].assessmentDetails.role);
    try {
      if (candidateDetailsResponse?.status === candidateStatus.inProgress) {
        localStorage.removeItem('sessionToken');
        navigate(`${RouterNames.assessment}/${id}/${null}`);
      }
      const isSubmittedOrTimeExpired =
        (candidateDetailsResponse?.status === candidateStatus.submitted ||
          candidateDetailsResponse?.status === candidateStatus.notAttempted) &&
        id !== demoAssessmentId;
      if (
        (isSubmittedOrTimeExpired &&
          candidateDetailsResponse?.isTimeExtended === true &&
          candidateDetailsResponse?.extraTime > 0) ||
        (isSubmittedOrTimeExpired &&
          candidateDetailsResponse?.isTimeExtended === false &&
          candidateDetailsResponse?.extraTime === 0)
      ) {
        removeItem(StorageKeys.WarningCount + id);
        removeItem(StorageKeys.ProctorStatus + id);
        removeItem(leavingTabsCount);
        removeItem(StorageKeys.candidateName);
        navigate(`${RouterNames.assessmentCompleted}/${id}`);
      }
      if (response === undefined) {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };
  const saveTimer = async () => {
    try {
      const testDuration = candidateDetails?.extraTime;
      const result = await setTime(id!, testDuration, convertTime(testDuration));
      return result?.data?.status === 'Success';
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatus = async () => {
    try {
      const params = {
        inProgress: true,
        timeSpent: candidateDetails?.timeSpent,
        markedForReview: candidateDetails?.markedForReview,
      };
      const result = await updateAssessmentStatus(params, id!);
      return result?.status === 'success';
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPlagiarismDetails = async () => {
    try {
      const plagiarismDetails = await getPlagiarismDetails(id!);
      setItem('pasteCount', plagiarismDetails?.data?.data[0]?.ctrlV);
      setItem(
        StorageKeys.WarningCount + id,
        plagiarismDetails?.data?.data[0]?.movementDetectionCount,
      );
      setItem(`markedForReview`, JSON.stringify(candidateDetails?.markedForReview));
      return plagiarismDetails?.data?.status === 'success';
    } catch (error) {
      console.log(error);
    }
  };
  const onClickProceedHandler = async () => {
    localStorage.setItem('currentQuestionIndex', '-1');
    localStorage.removeItem('sessionToken');
    try {
      if (
        (candidateDetails?.status === candidateStatus.submitted ||
          candidateDetails?.status === candidateStatus.notAttempted) &&
        candidateDetails?.extraTime > 0 &&
        candidateDetails?.isTimeExtended === false
      ) {
        const plagarismResult = await fetchPlagiarismDetails();
        const statusResult = await updateStatus();
        const timerResult = await saveTimer();
        plagarismResult &&
          statusResult &&
          timerResult &&
          navigate(`${RouterNames.assessment}/${id}/${null}`);
      } else {
        navigate(`${RouterNames.assessmentDetails}/${id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);

    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setThemeColor(response?.data?.data?.firstColor ?? '');
    setProfileDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchProfileDetails();
    fetchUserDetails();
    setItem('enterpriseId', enterpriseId!);
    removeItem('preAssessmentChecksCompleted');
  }, []);

  return (
    <StyledContainer>
      <StyledHeader>
        {/* To do for later*/}
        {/* <div>{CompanyDetails.assessment}</div> */}
        <LogoImage
          src={
            profileDetails?.imageURL
              ? profileDetails?.imageURL
              : enterpriseLogo
                ? enterpriseLogo
                : zecodeLogo
          }
          alt='zessta Logo'
        />
      </StyledHeader>
      <StyledContent>
        <ContentLeft>
          <InvitationText>
            {commonStrings.assessment.assessmentStartPage.descriptionTextOne}
            <br />
            {commonStrings.assessment.assessmentStartPage.descriptionTextTwo}
          </InvitationText>
          <StyledDivider />
          <InvitationTextDescription>
            {commonStrings.assessment.assessmentStartPage.note}
          </InvitationTextDescription>
        </ContentLeft>
        <StyledCard>
          <CardHeading>
            {commonStrings.assessment.assessmentStartPage.candidateInvitationCardHeading}{' '}
            {userName ? userName : candidateName},
          </CardHeading>
          <CardContent>
            <CardDescription>
              {commonStrings.assessment.assessmentStartPage.welcomeNote}
              {enterpriseName} ,
            </CardDescription>
            <CardDivider />
            <CardShortNote>
              {commonStrings.assessment.assessmentStartPage.descriptionNote}
              {userRole}
            </CardShortNote>
          </CardContent>
          <Button
            size='medium'
            variant='contained'
            onClick={onClickProceedHandler}
            sx={proceedButtonSx}
          >
            {commonStrings.assessment.assessmentStartPage.buttonText}
          </Button>
        </StyledCard>
      </StyledContent>
      <Footer>
        {/* To do for later */}
        {/* <HelpIconWrapper>
          <img src={HelpIcon} alt='HelpIcon' />
          <p color='white'>Help</p>
        </HelpIconWrapper> */}
        <ZecodeUrl />
        <ZecodeText />
      </Footer>
    </StyledContainer>
  );
};

export default CandidateInvitation;

export const proceedButtonSx = {
  width: '100%',
  margin: 0,
  p: 1,
  fontSize: '14px',
};
