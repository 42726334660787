import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { colors, RouterNames } from '../../static/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeContext } from '../context/themeContext';
import { getItem, setItem } from '../../util/storage';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import CustomizedStepper from '../common/customizedStepper';
import { getCandidateDetails, updateAssessmentStatus } from '../../api/requests/candidate';
import { getEnterpriseById } from '../../api/requests/enterprise';
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined';
import { saveCandidateForPlagarismDetails } from '../../api/requests/plagarism';
import { setTime } from '../../api/requests/assessmentTimer';
import { convertTime } from '../../util/convertTime';
import { getFilteredPreAssessmentCheckSteps } from '../../util/getPreAssessmentSteps';
const zecodeLogo = '/images/themeZecodeLogo.svg';
const preAssessmentChecksBgImage = '/images/preAssessmentChecksBgImage.svg';

const PreAssessmentChecks = () => {
  const navigate = useNavigate();
  const { setThemeColor } = useContext(ThemeContext);
  const { id } = useParams();
  const enterpriseID = getItem('enterpriseId');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [enterpriseLogo, setEnterpriseLogo] = useState<string>('');
  const [profileLogo, setProfileLogo] = useState<string>('');
  const [userData, setUserData] = useState<{ testDuration: string }>();

  const onClickHandler = async () => {
    const timerResponse = await saveTimer();
    await saveCandidateForPlagarismDetails(id!);
    const statusResponse = await updateStatus();
    timerResponse && statusResponse && navigate(`${RouterNames.assessment}/${id}/${null}`);
    setItem('preAssessmentChecksCompleted', 'true');
  };

  const fetchCandidateDetails = async () => {
    const response = await getCandidateDetails(id!);
    setUserData(response?.data?.data[0]?.assessmentDetails);
  };

  const updateStatus = async () => {
    try {
      const params = { inProgress: true };
      const response = await updateAssessmentStatus(params, id!);
      return (await response?.status) === 'success';
    } catch (error) {
      console.error(error);
    }
  };

  const saveTimer = async () => {
    try {
      const timeDuration = userData?.testDuration?.split(':') ?? ['00', '00'];
      const testDurationMinutes = parseInt(timeDuration[0]) * 60 + parseInt(timeDuration[1]);
      const response = await setTime(id!, 0, convertTime(testDurationMinutes));
      return (await response?.data?.message) === 'Success';
    } catch (error) {
      console.error(error);
    }
  };

  const getLogo = () => {
    return profileLogo ? profileLogo : enterpriseLogo ? enterpriseLogo : zecodeLogo;
  };

  const getTheme = async () => {
    const themeResponse = enterpriseID && (await getEnterpriseTheme(enterpriseID!));
    if (themeResponse && themeResponse?.data && themeResponse?.data?.data) {
      setThemeColor(themeResponse?.data?.data?.firstColor ?? '');
      setProfileLogo(themeResponse?.data?.data?.imageURL);
    }

    const enterprise = enterpriseID && (await getEnterpriseById(enterpriseID!));
    enterprise && setEnterpriseLogo(enterprise?.data?.data?.imageURL);
  };

  useEffect(() => {
    getTheme();
    fetchCandidateDetails();
  }, []);

  const header = (
    <Box sx={headerLogoSx}>
      <Box component={'img'} src={zecodeLogo} alt='zecode-logo' />
      <Box component={'img'} src={getLogo()} alt='enterprise-logo' sx={{ height: 50, width: 90 }} />
    </Box>
  );

  return (
    <Box sx={preAssessmentCheckMainContainerSx}>
      <>
        {header}
        <Box sx={cardContainerSx}>
          <Box sx={checksMainContainerSx}>
            <Typography sx={preAssessmentCheckTextSx}>Pre-Assessment Check</Typography>
            <Box sx={checksGridContainerSx}>
              <CustomizedStepper
                steps={getFilteredPreAssessmentCheckSteps()}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </Box>
            <Button
              onClick={onClickHandler}
              variant='contained'
              size='medium'
              sx={buttonSx}
              disabled={activeStep < getFilteredPreAssessmentCheckSteps().length}
            >
              Start Assessment
              <TrendingFlatOutlinedIcon />
            </Button>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default PreAssessmentChecks;

const preAssessmentCheckMainContainerSx: SxProps<Theme> = {
  width: '100vw',
  height: '100vh',
  background: colors.ghostWhite,
  padding: '22px 24px 90px 24px',
};

const cardContainerSx: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  background: colors.white,
  backgroundImage: `url(${preAssessmentChecksBgImage})`,
  backgroundSize: 'cover',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '80px 153px 32px 153px',
};

const headerLogoSx: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '23px',
};

const checksMainContainerSx: SxProps<Theme> = {};

const checksGridContainerSx: SxProps = {
  marginTop: 10,
};

const buttonSx: SxProps = {
  padding: '6px 10px 6px 10px',
  fontWeight: 500,
  color: colors.chineseBlack,
  marginTop: 10,
  alignItems: 'center',
};

const preAssessmentCheckTextSx: SxProps = {
  color: colors.darkLiverGray,
  fontSize: '30px',
  fontWeight: 300,
};
